import reactImg from "../../assets/img/react-js-icon.svg";
import tailwindImg from "../../assets/img/tailwind-css.svg";
import nextJs from "../../assets/img/nextjs.svg";
import nodeJs from "../../assets/img/nodejs.svg"
import githubIcon from "../../assets/img/github.svg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>I bring a versatile skill set to the table, specializing in web development technologies that power modern and dynamic online experiences.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={reactImg} alt="img" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img src={tailwindImg} alt="img" />
                                <h5>Tailwind CSS</h5>
                            </div>
                            <div className="item">
                                <img src={nextJs} alt="img" />
                                <h5>NextJs</h5>
                            </div>
                            <div className="item">
                                <img src={nodeJs} alt="img" />
                                <h5>NodeJs</h5>
                            </div>
                            <div className="item">
                                <img src={githubIcon} alt="img" />
                                <h5>GitHub</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="img" />
    </section>
  )
}
export default Skills