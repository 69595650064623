import { Col, Container, Row } from "react-bootstrap"
import contactImg from "../../assets/img/contact-img.svg"

export const Contact = () => {
    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <img src={contactImg} alt="Contact Us"/>
                    </Col>
                    <Col md={6}>
                        <h2>Get In Touch</h2>
                        <h3>📧: <a href="mailto:benprice700@gmail.com">ben.price700@gmail.com</a></h3>
                        <h4>☎️: 021 206 0452</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Contact