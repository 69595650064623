import './css/App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import WeatherApp from './pages/WeatherApp';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={Home}/>
        <Route path='/WeatherApp' element={WeatherApp}/>
        <Route path='/WeatherAppSrc'
        Component={() => {
          window.location.href = "https://github.com/benprice2/simple_weather_app"
          return null;
        }} />
        <Route path='/CrudOperations'
        Component={() => {
          window.location.href = "https://crudexercises.firebaseapp.com/"
          return null;
        }} />
        <Route path='/ExerciseApp'
        Component={() => {
          window.location.href = "https://bpcv-exerciseapp.firebaseapp.com/"
          return null;
        }} />
        <Route path='/ExerciseAppSrc'
        Component={() => {
          window.location.href = "https://github.com/benprice2/exercise_app"
          return null;
        }} />
        <Route path='/ninjaFood'
        Component={() => {
          window.location.href = "https://ninja-tailwind.firebaseapp.com/"
          return null;
        }} />
        <Route path='/ninjaFoodSrc'
        Component={() => {
          window.location.href = "https://github.com/benprice2/ninja_tailwind"
          return null;
        }} />
      </Routes>
    </BrowserRouter>
  );
}
