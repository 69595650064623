import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import linkImg from "../../assets/img/link.svg";
import srcCodeImg from "../../assets/img/source-code.svg";

export const ProjectCard = ({ title, skill1, skill2, imgUrl, path, srcPath }) => {
  return (
    <Col size={12} sm={6} md={4}>
        <div className="proj-imgbx">
            <img src={imgUrl} alt="project_img" className="proj-img"/>
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{skill1}{skill2 && `, ${skill2}`}</span>
              <div className="proj-btn-container">
                {path && <Link to={path}><button className="proj-link-btn"><img src={linkImg} alt="link"/></button></Link>}
                {srcPath && <Link to={srcPath}><button className="proj-src-btn"><img src={srcCodeImg} alt="source code"/></button></Link>}
              </div>
            </div>
        </div>
    </Col>
  )
}