import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImgWeatherApp from "../../assets/img/project_img_weatherapp.png";
import projImgCrudOpertations from "../../assets/img/project_img_crudoperations.png";
import projImgExerciseApp from "../../assets/img/project_img_exerciseapp.png"
import projDefaultImg from "../../assets/img/default-proj-img.png";
import projectImgFictionalUniversity from "../../assets/img/project_img_fictionUniversity.png"
import projImgNinjaFood from "../../assets/img/project_img_ninja.png";
import colorSharp2 from "../../assets/img/color-sharp2.png";

export const Projects = () => {

  const api_projects = [
    {
      title: "Weather Widget",
      skill1: "React",
      skill2: "API",
      imgUrl: projImgWeatherApp,
      path: '/WeatherApp',
      srcPath: '/WeatherAppSrc'
    },
    {
      title: "CRUD Operations",
      skill1: "React",
      skill2: "API",
      imgUrl: projImgCrudOpertations,
      path: '/CrudOperations',
    },
    {
      title: "Exercise App",
      skill1: "React",
      skill2: "API",
      imgUrl: projImgExerciseApp,
      path: '/ExerciseApp',
      srcPath: '/ExerciseAppSrc'
    }
  ];

  const other_projects = [
    {
      title: "Ninja Food",
      skill1: "Tailwind CSS",
      skill2: "NodeJS",
      imgUrl: projImgNinjaFood,
      path: '/ninjaFood',
      srcPath: 'ninjaFoodSrc'
    }
  ];

  const coming_soon = [
    {
      title: "Fictional University",
      skill1: "WordPress",
      imgUrl: projectImgFictionalUniversity, 
      path: null,
      srcPath: null
    },
    {
      title: "Hulu Clone",
      skill1: "NextJs",
      skill2: "Tailwind CSS",
      imgUrl: projDefaultImg, 
      path: null,
      srcPath: null
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
              <div>
                <h2>Projects</h2>
                <p></p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">API Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Other Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Coming Soon</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          api_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          other_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          coming_soon.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" alt="background-img" src={colorSharp2} ></img>
    </section>
  )
}

export default Projects