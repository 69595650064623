import { Container, Row, Col } from "react-bootstrap"
import logo from "../../assets/img/logo.png"
import navIcon1 from "../../assets/img/nav-icon1.svg"
import navIcon2 from "../../assets/img/nav-icon2.svg"
import navIcon3 from "../../assets/img/nav-icon3.svg"

export const Footer = () => {
    return (
        <footer className="footer" >
            <Container>
                <Row className="align-item-center">
                <Col sm={6} >
                    <div className="footer-logo">
                        <img src={logo} alt="logo"/>
                    </div>
                </Col>
                <Col sm={6} className="text-center text-sm-end">
                    <div className="social-icon"> 
                    <a href = "https://www.linkedin.com/in/ben-price-25891226b/"><img src={navIcon1} alt="" /></a>
                    <a href = "https://www.facebook.com/ben.price.31586526/"><img src={navIcon2} alt="" /></a>
                    <a href = "https://www.instagram.com/ben.price700"><img src={navIcon3} alt="" /></a>
                    </div>
                    <p>CopyRight 2023. All Rights Reserved by Ben Price</p>
                </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;